<template>
  <div class="header-box" :style="customStyleHeader">
    <svg class="header-cinema-background" width="286" height="161" v-bind:fill="fillColor" id="simulated" v-if="isCinemaViewType">
      <defs>
        <mask id="mask">
          <rect width="100%" height="161" fill="#ffffff"></rect>
          <circle transform="rotate(-180 125 0)" id="c" r="63" fill="rgb(90%,90%,90%)"></circle>
          <circle transform="rotate(-90 100 25)" id="c" r="30" fill="rgb(90%,90%,90%)"></circle>
        </mask>
      </defs>
      <path
        mask="url(#mask)"
        d="M 20 0 h 246 a 20 20 0 0 1 20 20 v 121 a 20 20 0 0 0 -20 20 h -246 a 20 20 0 0 0 -20 -20 v -121 a 20 20 0 0 1 20 -20 Z"
      ></path>
    </svg>

    <div class="header-regular-background" :style="customStyleBackground" v-else>

    </div>

    <div class="header-discount-factor" v-if="discountString">
      {{ discountString }}
    </div>

    <div class="header-content has-text-centered">
      <div class="header-title"
           :class="{'has-three-lines': event.id === 1294 || event.id === 1343 || event.id === 245}" :style="headerCustomTextStyles">
        {{ formattedTitle }}
      </div>

      <template v-if="discountOldPrice">
        <div class="header-discount-text">
          {{ discountOldPrice }}
        </div>
        <div class="header-bigger-subtitle header-discount-new-price">
          {{ biggerText }}
          <sub>{{ biggerTextSub }}</sub>
        </div>
        <div class="header-smaller-subtitle" v-if="smallerText">
          {{ smallerText }}
          <sub>{{ smallerTextSub }}</sub>
        </div>
      </template>
      <template v-else-if="priceVisible">
        <div class="header-bigger-subtitle">
          {{ biggerText }}
          <sub>{{ biggerTextSub }}</sub>
        </div>
        <div class="header-smaller-subtitle" v-if="smallerText">
          {{ smallerText }}
          <sub>{{ smallerTextSub }}</sub>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import {mapState} from "vuex";

export default {
  name: "EventTicketHeaderView",

  props: {
    title: {
      type: String,
    },

    price: {
      type: Number,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },

    pretaxPriceVisible: {
      type: Boolean,
      default: false,
    },

    pretaxPriceHighlighted: {
      type: Boolean,
      default: false,
    },

    eventTicketsVat: {
      type: Number,
      required: true,
    },

    ticketId: {
      type: Number,
      required: true,
    },
    ticketViewType: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState(["event"]),

    isUpgrade2023Event() {
      return this.event.id === 1355;
    },

    isKNM2024Event() {
      return this.event.id === 1441;
    },

    isAIBAConference() {
      return this.event.id === 1559;
    },

    priceVisible() {
      return this.event.id !== 1273 &&
        this.event.id !== 1343 &&
        this.event.id !== 245 &&
        this.event.id !== 1408 &&
        this.event.id !== 1411 &&
        this.event.id !== 1419 &&
        this.event.id !== 1312 &&
        this.event.id !== 1558 &&
        this.event.id !== 1575 &&
        this.event.id !== 1507 ||
        (this.event.id === 1509 && (this.ticketId !== 613 && this.ticketId !== 603));
    },

    formattedTitle() {
      return this.title.replace(' ', '\n');
    },

    customStyleHeader() {
      if (this.event.id === 1251 || this.event.id === 1294) {
        return {
          flex: '0 0 161px',
        };
      } else {
        return null;
      }
    },

    fillColor() {
      // EV Experience - custom
      if (this.ticketId === 515 || this.ticketId === 518) {
        return '#02A2CE';
      }
      if (this.isAIBAConference) {
        return '#000000';
      }
      return 'var(--event-ticket-header-background-color)';
    },

    headerCustomTextStyles() {
      if (this.isAIBAConference) {
        return { 'color': 'var(--primary-color) !important' };
      } else {
        return {};
      }
    },

    customStyleBackground() {
      if (this.ticketId === 580) {
        return {
          backgroundColor: '#FBBA00',
        };
      }
      if (this.event.id === 1251 || this.event.id === 1294) {
        return {
          height: '161px',
        };
      } else {
        return null;
      }
    },

    discountString() {
      if (this.event.id === 1251) {
        return `-${this.event.marketing_discount_percentage}%`;
      } else {
        return false;
      }
    },

    discountOldPrice() {
      if (this.event.id === 1251) {
        return this.$options.filters.currency(this.price, {
          locale: "pl-PL",
          currency: this.currency
        }) + ' ' + this.biggerTextSub;
      } else {
        return false;
      }
    },

    pretaxPrice() {
      const factor = 1.0 + this.eventTicketsVat;
      return this.price / factor;
    },

    biggerText() {
      if (this.event.id === 1251) {
        return this.$options.filters.currency(this.price * this.event.marketing_discount_percentage * 0.01, {
          locale: "pl-PL",
          currency: this.currency
        }) + '*';
      }
      if (this.pretaxPriceVisible && this.pretaxPriceHighlighted) {
        return this.$options.filters.currency(this.pretaxPrice, {locale: "pl-PL", currency: this.currency});
      } else {
        return this.$options.filters.currency(this.price, {locale: "pl-PL", currency: this.currency});
      }
    },

    biggerTextSub() {
      if (this.isUpgrade2023Event) {
        return `+ ${this.$t("payment.vat")}`;
      } else if (this.pretaxPriceVisible && this.pretaxPriceHighlighted) {
        return `(${this.$t("payment.net")})`;
      } else {
        return `(${this.$t("payment.gross")})`;
      }
    },

    smallerText() {
      if (this.isUpgrade2023Event || this.isKNM2024Event) {
        return "";
      } else if (this.ticketId === 515 || this.ticketId === 518) {
        return null;
      } else if (this.pretaxPriceVisible && this.pretaxPriceHighlighted) {
        return this.$options.filters.currency(this.price, {locale: "pl-PL", currency: this.currency});
      } else if (this.pretaxPriceVisible && !this.pretaxPriceHighlighted) {
        return this.$options.filters.currency(this.pretaxPrice, {locale: "pl-PL", currency: this.currency});
      } else {
        return null;
      }
    },

    smallerTextSub() {
      if (this.pretaxPriceVisible && this.pretaxPriceHighlighted) {
        return `(${this.$t("payment.gross")})`;
      } else if (this.pretaxPriceVisible && !this.pretaxPriceHighlighted) {
        return `(${this.$t("payment.net")})`;
      } else {
        return null;
      }
    },

    isCinemaViewType() {
      return this.ticketViewType === LpConfigConstants.EVENT_TICKET_VIEW_TYPES.CINEMA;
    },
  },

  filters: {
    currency(value, {locale, currency}) {
      const opts = {style: "currency", currency: currency, minimumFractionDigits: 2};
      const formatter = new Intl.NumberFormat(locale, opts);
      const formatted = formatter.format(Number(value));
      return formatted.replace(/,00/, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.header-box {
  width: 286px;
  flex: 0 0 161px;
  position: relative;
}

.header-cinema-background {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.header-regular-background {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: var(--event-ticket-header-background-color);
  width: 286px;
  height: 161px;
  border-top-left-radius: var(--event-ticket-border-radius);
  border-top-right-radius: var(--event-ticket-border-radius);
}

.header-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.header-discount {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: line-through;
  right: -5%;
  top: 40%;
  bottom: 32%;
  background: var(--link-color);
  padding: 4px 12px;
  font-weight: normal;
  font-size: 18px;
  color: white;
}

.header-discount-text {
  font-weight: normal;
  font-size: 14px;
  color: white;
  text-decoration: line-through;
  margin-top: 4px;
}

.header-discount-new-price {
  background: var(--link-color);
  padding: 6px 12px;
  font-weight: normal;
  font-size: 18px;
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.header-discount-factor {
  position: absolute;
  right: -5%;
  top: -10%;
  background: var(--link-color);
  padding: 4px 12px;
  font-weight: bold;
  font-size: 18px;
  color: white;
}

.header-title {
  width: 100%;
  font-style: normal;
  font-weight: bold;
  line-height: 130%;
  font-size: 24px;
  color: var(--primary-color-text);
  display: -webkit-box;
  white-space: pre-line;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.has-three-lines {
    -webkit-line-clamp: 3;
  }
}

.header-bigger-subtitle {
  font-style: normal;
  font-weight: 700;
  margin-top: 4px;
  font-size: 20px;
  line-height: 23px;
  color: var(--primary-color-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  sub {
    vertical-align: baseline;
    font-size: 0.6em;
  }
}

.header-smaller-subtitle {
  font-style: normal;
  font-weight: 500;
  margin-top: 4px;
  font-size: 15px;
  line-height: 23px;
  color: var(--primary-color-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  sub {
    vertical-align: baseline;
    font-size: 0.5em;
  }
}
</style>
