<template>
  <div class="columns is-centered">
    <div class="column is-two-thirds" v-if="isMebleEvent || isProExpertEvent || isXEvent || isFlukeEvent || is1584Event || isVCEvent">
      <div class="has-text-centered mb-4">
        <h2 class="title is-spaced is-2 has-text-primary" v-if="isFlukeEvent">{{ $t("custom.fluke_payment_final_step_title") }}</h2>
        <h2 class="title is-spaced is-2 has-text-primary" v-else-if="is1584Event">{{ $t("custom.1584_payment_final_step_title") }}</h2>
        <h2 class="title is-spaced is-2 has-text-primary" v-else-if="isVCEvent">{{ $t("custom.vc_payment_final_step_title") }}</h2>
        <h2 class="title is-spaced is-2 has-text-primary" v-else>{{ $t("custom.meble_confirmation_title") }}</h2>
        <p class="subtitle is-4 has-text-weight-semibold" v-if="isFlukeEvent">
          {{ $t("custom.fluke_payment_final_step_subtitle") }}
        </p>
        <p class="subtitle is-4 has-text-weight-semibold" v-else-if="isVCEvent">
          {{ $t("custom.vc_payment_final_step_subtitle") }}
        </p>
        <p class="subtitle is-4 has-text-weight-semibold" v-else-if="isProExpertEvent">
          {{ $t("custom.ped_confirmation_subtitle") }}
        </p>
        <p class="subtitle is-4 has-text-weight-semibold" v-else-if="is1584Event">
          {{ $t("custom.1584_payment_final_step_subtitle") }}
        </p>
        <p class="subtitle is-4 has-text-weight-semibold" v-else>
          {{ $t("custom.meble_confirmation_subtitle") }}
        </p>
      </div>

      <template v-if="isFastLaneEvent">
        <div class="has-text-centered">
          <button class="button is-primary" @click="openFastLane">
            {{ $t("payment.return_home") }}
          </button>
        </div>
      </template>
      <template v-else>
        <div class="has-text-centered">
          <router-link
            :to="{ name: Constants.ROUTE_MAIN_INTRODUCTION, hash: isTargiSpecjalEvent ? '#module-7' : '' }"
            class="button is-primary"
          >
            {{ $t("payment.return_home") }}
          </router-link>
        </div>
      </template>
    </div>

    <div class="column is-two-thirds" v-else-if="isProformaPurchase">
      <div class="has-text-centered mb-4">
        <h2 class="title is-spaced is-2 has-text-primary">{{ viewTitle }}</h2>
        <p class="subtitle is-4 has-text-weight-semibold" v-html="viewSubtitle"></p>
      </div>

      <div class="mb-4">
        <div class="is-relative">
          <b-loading :active="isProformaDownloading" :is-full-page="false"></b-loading>
          <a class="box is-block" @click.prevent="requestProformaDownload">
            <div class="media is-vcentered">
              <div class="media-content">
                <p class="is-size-7 is-ticket-details has-text-primary has-text-weight-semibold">Faktura proforma</p>
                <h3 class="title is-4">
                  {{ title }}
                </h3>
              </div>
              <div class="media-right">
                <download-icon class="download-icon"></download-icon>
              </div>
            </div>
          </a>
        </div>
      </div>

      <template>
        <div class="has-text-centered">
          <router-link
            :to="{ name: Constants.ROUTE_MAIN_INTRODUCTION, hash: isTargiSpecjalEvent ? '#module-7' : '' }"
            class="button is-primary"
          >
            {{ $t("payment.return_home") }}
          </router-link>
        </div>
      </template>
    </div>

    <div class="column is-two-thirds" v-else-if="ticket">
      <div class="has-text-centered mb-4">
        <h2 class="title is-spaced is-2 has-text-primary">{{ viewTitle }}</h2>
        <template v-if="isTargiSpecjalEvent">
          <p class="subtitle is-4 has-text-weight-semibold">{{ $t("custom.targi_specjal_final_success_subtitle_p1") }}</p>
          <p class="subtitle is-4 has-text-weight-semibold">{{ $t("custom.targi_specjal_final_success_subtitle_p2") }}</p>
        </template>
        <template v-else>
          <p class="subtitle is-4 has-text-weight-semibold" v-html="viewSubtitle"></p>
        </template>
      </div>

      <div class="mb-4" v-if="!isTargiSpecjalEvent">
        <div class="is-relative">
          <b-loading :active="isDownloading" :is-full-page="false"></b-loading>
          <a class="box is-block" @click.prevent="requestTicketDownload">
            <div class="media is-vcentered">
              <div class="media-content">
                <p class="is-size-7 is-ticket-details has-text-primary has-text-weight-semibold">
                  {{ date | date("YYYY-MM-DD") }}
                  <template v-if="ticketType"> &bullet; {{ $t(ticketType) }}</template>
                  &bullet; {{ ticketName }}
                </p>
                <h3 class="title is-4">
                  {{ title }}
                </h3>
              </div>
              <div class="media-right">
                <download-icon class="download-icon"></download-icon>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div class="has-text-centered mb-2" v-if="!isDtsEvent && !isTargiSpecjalEvent">
        <p class="has-text-weight-semibold subtitle">
          {{ $t("payment.ticket_email_info") }}
        </p>
      </div>

      <template v-if="isDtsEvent">
        <div class="has-text-centered mb-3" v-if="passwordSet">
          <div>
            {{ $t("custom.dts_description_after_password_is_set") }}
          </div>
          <button class="button is-primary mt-2" @click="openMyProfile">
            {{ $t("custom.dts_description_after_password_is_set_button") }}
          </button>
          <p class="subtitle is-4 mt-3">
            {{ $t("custom.dts_description_after_password_is_set_2") }}
          </p>

          <mobile-app-info-component v-if="mobileAppInfoModuleData" :module-data="mobileAppInfoModuleData"></mobile-app-info-component>
        </div>
        <div v-else class="mb-2" :class="{ 'has-text-centered': !showPasswordForm }">
          <template v-if="showPasswordForm">
            <edit-password-form class="mt-4" ref="form" :error="error" @submit="updatePassword"></edit-password-form>
          </template>
          <template v-else class="mb-2">
            <button class="button is-primary mt-2" @click="openChangePassword">{{ $t("auth.set_password_button") }}</button>
          </template>
        </div>
      </template>

      <template v-if="isFastLaneEvent">
        <div class="has-text-centered">
          <button class="button is-primary" @click="openFastLane">
            {{ $t("payment.return_home") }}
          </button>
        </div>
      </template>
      <template v-else>
        <div class="has-text-centered">
          <router-link
            :to="{ name: Constants.ROUTE_MAIN_INTRODUCTION, hash: isTargiSpecjalEvent ? '#module-7' : '' }"
            class="button is-primary"
          >
            {{ $t("payment.return_home") }}
          </router-link>
        </div>
      </template>
    </div>
    <div class="column is-two-thirds" v-else-if="transactionStatus === PaymentConstants.TRANSACTION_STATUSES.CANCELED">
      <div class="has-text-centered mb-4 mt-6">
        <h2 class="title is-spaced is-2 has-text-danger">{{ $t("payment.final_canceled_title") }}</h2>
        <p class="subtitle is-4 has-text-weight-semibold mt-2">{{ $t("payment.final_canceled_subtitle") }}</p>
      </div>
      <div class="mb-4 mt-4 has-text-centered">
        <div>
          <button v-on:click="$emit('restart')" class="button is-primary">
            {{ $t("payment.try_again") }}
          </button>
        </div>
        <div>
          <template v-if="isFastLaneEvent">
            <button class="button is-primary" @click="openFastLane">
              {{ $t("payment.return_home") }}
            </button>
          </template>
          <template v-else>
            <router-link
              :to="{ name: Constants.ROUTE_MAIN_INTRODUCTION, hash: isTargiSpecjalEvent ? '#module-7' : '' }"
              class="button is-primary"
            >
              {{ $t("payment.return_home") }}
            </router-link>
          </template>
        </div>
      </div>
    </div>

    <div v-else-if="transactionStatus === PaymentConstants.TRANSACTION_STATUSES.COMPLETED && !isAuthorized">
      <div class="has-text-centered mb-4 mt-6">
        <template v-if="isTargiSpecjalEvent">
          <h2 class="title mb-4 is-spaced is-2 has-text-primary">{{ $t("payment.final_free_success_title") }}</h2>
          <p class="subtitle is-4 has-text-weight-semibold">{{ $t("custom.targi_specjal_final_success_subtitle_p1") }}</p>
          <p class="subtitle is-4 has-text-weight-semibold">{{ $t("custom.targi_specjal_final_success_subtitle_p2") }}</p>
        </template>

        <template v-else>
          <h2 class="title is-spaced is-2 has-text-primary" v-if="isPismEvent || areAllFree">
            {{ $t("ticket.free_ticket_confirmation_title") }}
          </h2>

          <h2 v-else class="title is-spaced is-2 has-text-primary">{{ $t("payment.final_success_subtitle") }}</h2>

          <p
            class="subtitle is-4 has-text-weight-semibold mt-3"
            v-if="isDolbyEvent"
            v-html="$t('custom.dolby_custom_final_free_purchase')"
          ></p>

          <p
            class="subtitle is-4 has-text-weight-semibold mt-3"
            v-else-if="isPismEvent || areAllFree"
            v-html="$t('ticket.free_ticket_confirmation_subtitle')"
          ></p>

          <p class="subtitle is-4 has-text-weight-semibold mt-3" v-else>{{ $t("payment.final_unauthorized_subtitle") }}</p>
        </template>
      </div>

      <template v-if="isFastLaneEvent">
        <div class="has-text-centered">
          <b-button class="button is-primary" @click="openFastLane">
            {{ $t("payment.return_home") }}
          </b-button>
        </div>
      </template>
      <template v-else>
        <div class="has-text-centered">
          <router-link
            :to="{ name: Constants.ROUTE_MAIN_INTRODUCTION, hash: isTargiSpecjalEvent ? '#module-7' : '' }"
            class="button is-primary"
          >
            {{ $t("payment.return_home") }}
          </router-link>
        </div>
      </template>
    </div>

    <div class="column is-two-thirds" v-else-if="retries > 6">
      <div class="has-text-centered mb-4 mt-6">
        <h2 class="title is-spaced is-2 has-text-warning">{{ $t("payment.final_unknown_title") }}</h2>
        <p class="subtitle is-4 has-text-weight-semibold mt-3">{{ $t("payment.final_unknown_subtitle") }}</p>
      </div>
      <div class="mb-4">
        <template v-if="isFastLaneEvent">
          <div class="has-text-centered">
            <b-button class="button is-primary" @click="openFastLane">
              {{ $t("payment.return_home") }}
            </b-button>
          </div>
        </template>
        <template v-else>
          <div class="has-text-centered">
            <router-link
              :to="{ name: Constants.ROUTE_MAIN_INTRODUCTION, hash: isTargiSpecjalEvent ? '#module-7' : '' }"
              class="button is-primary"
            >
              {{ $t("payment.return_home") }}
            </router-link>
          </div>
        </template>
      </div>
    </div>

    <div class="column is-two-thirds" v-else>
      <div class="has-text-centered mb-4 mt-6">
        <template v-if="isTargiSpecjalEvent">
          <h2 class="title is-spaced is-2 has-text-primary">{{ $t("payment.final_waiting_ticket_free_title") }}</h2>
          <p class="subtitle is-4 has-text-weight-semibold mt-3">{{ $t("payment.final_waiting_ticket_free_subtitle") }}</p>
        </template>
        <template v-else>
          <h2 class="title is-spaced is-2 has-text-primary">{{ $t("payment.final_waiting_title") }}</h2>
          <p class="subtitle is-4 has-text-weight-semibold mt-3">{{ $t("payment.final_waiting_subtitle") }}</p>
        </template>
      </div>
      <div class="mb-4">
        <div class="has-text-centered">
          <list-loading-indicator :active="true"></list-loading-indicator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DownloadIcon from "@/assets/icons/download.svg";
import { Constants } from "@/web/constants";
import PaymentConstants from "@/shared/constants/payment-constants";
import dayjs from "@/shared/utils/day";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import EditPasswordForm from "@/web/components/forms/EditPasswordForm";
import RequestMixin from "@/shared/mixins/RequestMixin";
import MobileAppInfoComponent from "@/web/components/mobileapp/MobileAppInfoComponent";
import axios from "axios";

export default {
  name: "PurchaseFinalStep",

  components: {
    MobileAppInfoComponent,
    EditPasswordForm,
    ListLoadingIndicator,
    DownloadIcon,
  },
  mixins: [RequestMixin],

  data() {
    return {
      isDownloading: false,
      isProformaDownloading: false,
      isCheckingTransactionStatus: false,
      checkTransactionStatusTimeout: null,
      retries: 0,
      passwordSet: false,
      showPasswordForm: false,
    };
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  mounted() {
    if (!this.isProformaPurchase) {
      this.startCheckingTransactionStatus();
    }
  },

  filters: {
    date(value, format) {
      return dayjs(value).format(format);
    },
  },

  requests: {
    async updatePassword(credentials) {
      await this.updateUserPassword({ ...credentials });
      this.onSetPasswordSuccess();
    },
  },

  methods: {
    ...mapActions("userTickets", ["downloadTicket", "loadTickets"]),
    ...mapActions("payment", ["checkTransactionStatus"]),
    ...mapActions("auth", ["updateUserPassword"]),

    openFastLane() {
      window.open("https://www.fastlineclub.com", "_self").focus();
    },

    requestTicketDownload() {
      if (this.isTargiSpecjalEvent) {
        return;
      }
      this.isDownloading = true;
      this.loadTickets()
        .then(() => this.downloadTicket())
        .catch(err => console.error(err))
        .finally(() => (this.isDownloading = false));
    },

    async requestProformaDownload() {
      try {
        this.isProformaDownloading = true;
        const response = await axios.get(this.invoiceUrl, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'proforma_invoice.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading proforma invoice:", error);
      } finally {
        this.isProformaDownloading = false;
      }
    },

    onSetPasswordSuccess() {
      this.passwordSet = true;
    },

    openChangePassword() {
      this.showPasswordForm = true;
    },

    navigateHome() {
      this.$router.push({ name: Constants.ROUTE_MAIN_INTRODUCTION });
    },

    openMyProfile() {
      this.$router.push({ name: Constants.ROUTE_MY_PROFILE });
    },

    startCheckingTransactionStatus() {
      if (this.orderId) {
        this.isCheckingTransactionStatus = true;
        this.checkTransactionStatus({
          orderId: this.orderId,
          hash: this.hash,
        });
      }
      this.nextUpdate();
    },

    stopCheckingTransactionStatus() {
      this.isCheckingTransactionStatus = false;
      if (this.checkTransactionStatusTimeout) {
        clearTimeout(this.checkTransactionStatusTimeout);
      }
    },

    nextUpdate() {
      if (this.isCheckingTransactionStatus && !this.ticket && this.retries < 6) {
        this.checkTransactionStatusTimeout = setTimeout(() => {
          this.checkTransactionStatus({
            orderId: this.orderId,
            hash: this.hash,
          });
          this.retries = this.retries + 1;
          this.nextUpdate();
        }, 10 * 1000);
      } else {
        this.stopCheckingTransactionStatus();
        if (!this.ticket) {
          this.loadTickets().then(() => {
            this.retries = this.retries + 1;
          });
        }
      }
    },
  },

  computed: {
    Constants: () => Constants,
    PaymentConstants: () => PaymentConstants,
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapState(["event", "settings"]),
    ...mapGetters("userTickets", ["ticket"]),
    ...mapGetters("eventTickets", ["areAllFree"]),
    ...mapState("payment", ["transactionStatus"]),
    ...mapGetters(["mobileAppModules"]),

    mobileAppInfoModuleData() {
      if (this.mobileAppModules && this.mobileAppModules.length) {
        return this.mobileAppModules[0];
      } else {
        return null;
      }
    },
    isSurvivalRaceKidsEvent() {
      return this.event.id === 1129;
    },

    isProformaPurchase() {
      return !!this.invoiceUrl;
    },

    invoiceUrl() {
      const params = this.$route.query;
      return params["invoice_url"] || null;
    },

    isPismEvent() {
      return this.event.id === 1312;
    },

    isDtsEvent() {
      return this.event.id === 1411;
    },

    isMebleEvent() {
      return this.event.id === 1343 || this.event.id === 245;
    },

    isFastLaneEvent() {
      return this.event.id === 1448;
    },

    isProExpertEvent() {
      return this.event.id === 1419;
    },

    isXEvent() {
      return this.event.id === 1440;
    },

    isFlukeEvent() {
      return this.event.id === 1507;
    },

    is1584Event() {
      return this.event.id === 1584;
    },

    isDolbyEvent() {
      return this.event.id === 1318;
    },

    isTargiSpecjalEvent() {
      return this.event.id === 1575;
    },

    isVCEvent() {
      return this.event.id === 1604;
    },

    ticketName() {
      return this.ticket.event_ticket.name;
    },

    ticketId() {
      return this.ticket.event_ticket.id;
    },

    ticketType() {
      const ticketTypes = this.settings.ticket_types;
      if (ticketTypes && ticketTypes[this.ticketId]) {
        return ticketTypes[this.ticketId];
      }
      return null;
    },

    isFree() {
      return this.ticket?.event_ticket.price === 0;
    },

    viewTitle() {
      if (this.isProformaPurchase) {
        return this.$t("payment.final_proforma_title");
      }
      if (this.isFree) {
        if (this.isPismEvent) {
          return this.$t("custom.pism_2023_confirmation_title");
        } else {
          return this.$t("payment.final_free_success_title");
        }
      } else {
        return this.$t("payment.final_success_title");
      }
    },

    viewSubtitle() {
      if (this.isProformaPurchase) {
        return this.$t("payment.final_proforma_subtitle");
      }
      if (this.isFree) {
        if (this.isDtsEvent) {
          return this.$t("custom.dts_success_subtitle");
        } else if (this.isSurvivalRaceKidsEvent) {
          return this.$t("custom.srk_final_success_subtitle");
        } else if (this.isPismEvent) {
          return this.$t("custom.pism_2023_confirmation_subtitle");
        } else if (this.isDolbyEvent) {
          return this.$t("custom.dolby_custom_final_free_purchase");
        } else {
          return this.$t("payment.final_free_success_subtitle");
        }
      } else {
        return this.$t("payment.final_success_subtitle");
      }
    },

    title() {
      return this.event.title;
    },

    date() {
      return this.event.start_date;
    },

    queryParams() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return [...urlSearchParams.entries()];
    },

    orderId() {
      const param = this.queryParams.find(([key, value]) => key === "order_id" || key === "OrderID");
      return param && param[1];
    },

    hash() {
      const param = this.queryParams.find(([key, value]) => key === "Hash");
      return param && param[1];
    },
  },

  watch: {
    transactionStatus: {
      immediate: true,
      handler: function (newValue) {
        if (newValue === PaymentConstants.TRANSACTION_STATUSES.COMPLETED) {
          this.stopCheckingTransactionStatus();
          setTimeout(() => this.loadTickets(), 5000);
        } else if (newValue === PaymentConstants.TRANSACTION_STATUSES.CANCELED) {
          this.stopCheckingTransactionStatus();
        }
      },
    },

    visible: {
      immediate: true,
      handler: function (newValue) {
        if (newValue && this.transactionStatus === PaymentConstants.TRANSACTION_STATUSES.COMPLETED) {
          setTimeout(() => this.loadTickets(), 5000);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.title.is-4,
.subtitle.is-4 {
  font-size: 24px;
}

.is-ticket-details {
  margin-bottom: 4px;
}

.download-icon {
  fill: #007aff;
  fill: var(--primary-color);
  display: block;
  width: 36px;
  height: 36px;
}
</style>
